@import './../theme-bootstrap';
$thumbnails-shown-on-desktop: 5;

.product-full {
  display: block;
  max-width: $max-width;
  margin: 0 auto;
  padding: 8px 0 0;
  position: relative;
  @include breakpoint($medium-up) {
    @include pie-clearfix;
    padding: 15px 0 25px;
  }
  &__extole {
    text-transform: uppercase;
  }
  .product-full__ratings {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    div.product-full__review-snippet {
      display: flex;
      justify-content: flex-start;
      .pr-snippet {
        &-rating-decimal {
          position: relative;
          top: 2px;
          @include breakpoint($medium-up) {
            top: 1px;
          }
        }
      }
    }
  }
}

.product-full__breadcrumb {
  padding: 0 16px;
  display: block;
  .product-breadcrumb {
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
}

.product-full__header {
  padding: 0 16px;
  text-align: $ldirection;
  @include breakpoint($medium-up) {
    float: $rdirection;
    width: 48%;
    padding: 0;
    text-align: $ldirection;
  }
  .product-full__ratings {
    div.product-full__review-snippet {
      display: flex;
      width: 100%;
      justify-content: start;
      .pr-snippet {
        &-rating-decimal {
          position: relative;
          top: 2px;
        }
      }
    }
  }
}

.product-full__breadcrumb {
  padding: 0 16px;
  .product-breadcrumb {
    padding: 0;
  }
}

.product-full__subline {
  @include t2;
  font-size: 12px;
  margin: 0 0 2px;
  font-family: $font--avenir-light;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 14px;
  }
}

.product-full__name {
  @include header--small;
  font-family: $font--avenir-book;
  margin: 0 auto;
  @include breakpoint($medium-up) {
    @include header--medium;
    font-family: $font--avenir-book;
    margin-#{$ldirection}: -2px;
    margin-bottom: 2px;
  }
}

.product-full__short-description {
  font-size: 14px;
  font-family: $font--avenir-book;
  margin-bottom: 35px;
  padding: 10px 0;
  @include breakpoint($medium-up) {
    margin-bottom: 0;
    padding-top: 0;
  }
}
.product-full__details {
  position: relative;
  background: $color-bg;
  padding: 0 10px 20px;
  @include breakpoint($medium-up) {
    background: none;
    margin-top: 0;
    float: $rdirection;
    width: 48%;
    margin-bottom: 30px;
    margin-#{$ldirection}: 30px;
    max-width: 528px;
    padding-#{$rdirection}: 20px;
    overflow: hidden;
    clear: $rdirection;
  }
  .selectBox {
    background: $color-white;
    &-label {
      color: $color-black;
    }
    @include breakpoint($medium-down) {
      width: 100%;
    }
  }
  .product-replenishment-select {
    font-size: 16px;
    padding: 10px 0;
    @include breakpoint($medium-up) {
      padding-bottom: 15px;
    }
  }
  .product-sku-price {
    margin: 0 0 7px;
    font-size: 19px;
    line-height: 1em;
    &__value {
      display: block;
    }
  }
}

.product-full__atb {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  .product__button--add-to-bag {
    width: calc(100% - 130px);
    min-width: 224px;
    height: 48px;
    margin-#{$ldirection}: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}

.product-full__add-to-bag {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  @include breakpoint($medium-up) {
    justify-content: flex-start;
    margin: 0 0 12px;
  }
  .quantity--menu__container {
    &.hidden {
      + .button {
        margin-#{$ldirection}: 0;
      }
    }
  }
  a.button,
  .button {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    flex: 1;
    height: 48px;
    justify-content: center;
    margin-#{$ldirection}: 10px;
    margin-top: 0;
    &.product-add-to-waitlist {
      margin-#{$ldirection}: 0;
    }
  }
  .product__button--shop-wholesale {
    margin-bottom: 14px;
    @include breakpoint($medium-up) {
      margin-bottom: 10px;
    }
  }
}

.product-full__options {
  margin: 15px 0;
  .sku-menu {
    &__single {
      font-size: 16px;
      @include breakpoint($medium-up) {
        font-size: 19px;
        line-height: 45px;
        margin-#{$rdirection}: 20px;
      }
    }
  }
  .product-select {
    margin: 1em 0;
    @include breakpoint($portrait-up) {
      float: $ldirection;
      margin-#{$rdirection}: 20px;
    }
  }
}

.product-full__badge {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 63px;
  z-index: 1;
  #{$ldirection}: 10px;
  @include breakpoint($medium-up) {
    width: 158px;
    height: 101px;
    #{$ldirection}: 35px;
    top: 10px;
  }
  $badges: (award, exclusive, limitededition, new, stylistpick, topseller);
  @each $badge in $badges {
    &--#{$badge} {
      background-image: url('/media/images/products/badges/badge_#{$badge}.png');
    }
  }
}

.product-full__wrapper {
  @include breakpoint($medium-up) {
    display: flex;
    margin-top: 48px;
  }
  .product-full__images {
    @include breakpoint($medium-up) {
      width: 62.5%;
    }
  }
  .product-full__image-wrapper {
    @include breakpoint($medium-up) {
      display: flex;
      gap: 20px;
    }
    .product-full__image-thumbnails {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
        width: calc(
          (75% / #{$thumbnails-shown-on-desktop}) - (140px / #{$thumbnails-shown-on-desktop})
        );
      }
      .slick-list {
        @include breakpoint($medium-up) {
          // Override inline Slick style to align thumbs slider with full image
          height: 46vw !important;
          max-height: 600px;
          padding: 0 !important;
        }
      }
      .slick-prev,
      .slick-next {
        background: rgba(255, 255, 255, 0.8);
        height: 16px;
        left: calc(50% - 16px);
        width: 32px;
      }
      .slick-prev {
        top: -16px;
        transform: scaleX(1.75);
      }
      .slick-next {
        bottom: -16px;
        top: auto;
        transform: rotateZ(180deg) scaleX(1.75);
      }
      .slick-prev::before,
      .slick-next::before {
        content: '\005e';
        font-family: $font--avenir-book;
        font-size: 16px;
      }
      .product-full__thumbnail-img {
        cursor: pointer;
        margin-bottom: 0.25rem;
      }
      .product-full__video-image .icon {
        height: 100%;
        left: 0;
        pointer-events: none;
        top: 0;
        transform: none;
        width: 100%;
        &::before {
          cursor: pointer;
          font-size: 18px;
          left: calc(50% - 9px);
          position: absolute;
          top: calc(50% - 9px);
        }
      }
    }
    .product-full__image {
      @include breakpoint($medium-up) {
        width: 75%;
      }
      .slick-arrow {
        visibility: visible;
        pointer-events: initial;
      }
      &.slick-initialized--video-playing {
        .slick-arrow {
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }
  .slick-dots {
    button {
      border-radius: 50%;
      border: 1px solid $color--gray;
      background: $color--gray;
      height: 10px;
      width: 10px;
      &::before {
        content: none;
      }
    }
    .slick-active button {
      background: $color--black;
      border: 1px solid $color--black;
      &::before {
        content: none;
      }
    }
  }
  .slick-dots {
    button {
      border-radius: 50%;
      border: 1px solid $color--gray;
      background: $color--gray;
      height: 10px;
      width: 10px;
      &::before {
        content: none;
      }
    }
    .slick-active button {
      background: $color--black;
      border: 1px solid $color--black;
      &::before {
        content: none;
      }
    }
  }
}

.product-full__image {
  .slick-dots {
    position: relative;
    top: -30px;
    margin: 0;
  }
}

.product-full__image-slide {
  position: relative;
  margin-bottom: 14px;
  &.slick-slide {
    margin-bottom: 0;
    img {
      margin: 0 auto;
      @include breakpoint($large-up) {
        min-width: 600px;
      }
    }
    .product-full__video-image {
      img {
        cursor: pointer;
        @include breakpoint($large-up) {
          min-width: unset;
        }
      }
    }
  }
}

.product-full__video {
  cursor: pointer;
  .close-video-button {
    display: none;
    position: absolute;
    padding: 7px;
    top: 0;
    #{$rdirection}: 0;
    background: $color-white;
    z-index: 2;
  }
  .revealed ~ .close-video-button {
    display: block;
  }
}

.product-full__video-image {
  position: relative;
  .icon {
    @include vertical-horizontal-center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: transform $transition-duration $transition-effect;
    backface-visibility: hidden;
    position: absolute;
    font-size: 60px;
    z-index: 1;
    color: $color-white;
    text-shadow: 1px 1px 1px $color-black;
    transform: translate(50%, -50%);
    &::before {
      position: absolute;
    }
    @include breakpoint($medium-up) {
      font-size: 70px;
    }
  }
  &:hover {
    .no-touchevents & {
      .icon {
        @include transform(scale(1.2));
      }
    }
  }
  &--play-inline {
    display: contents;
    position: absolute;
    top: 0;
    z-index: 1;
  }
}

.product-full__video-reveal {
  height: 100%;
  position: absolute;
  z-index: 2;
  width: 100%;
  iframe {
    max-width: 100vw;
    width: 100%;
    height: 100%;
  }
}

.product-full__ingredients-label {
  cursor: pointer;
}

.product-full__refer-extole {
  @include swap_direction(margin, 22px 0 10px 0);
  text-transform: uppercase;
  cursor: pointer;
}

.product-full__add-favorites {
  position: absolute;
  top: 5px;
  #{$rdirection}: 10px;
  z-index: 10;
  @include breakpoint($medium-up) {
    position: static;
    display: inline-block;
  }
  .product__button--add-favorites {
    font-size: 30px;
    &:hover {
      color: $color-link-hover;
    }
    @include breakpoint($medium-up) {
      margin-#{$rdirection}: 15px;
    }
  }
}

.product-full__social {
  margin-top: 10px;
  padding-top: 18px;
  border-top: 1px solid $color-border;
  position: relative;
  @include breakpoint($medium-up) {
    margin-top: 0;
    padding-top: 0;
    border: 0;
    display: inline-block;
  }
}

.product-full__products {
  font-size: 0;
  @include breakpoint($medium-up) {
    margin: 0 0 28px;
    &:empty {
      margin-bottom: 0;
    }
  }
  .button {
    margin-#{$rdirection}: 10px;
    margin-top: 5px;
    @include breakpoint($medium-up) {
      margin-top: 10px;
    }
  }
}

.product-full__detail {
  border-top: 1px solid $color-border;
  padding-top: 18px;
  margin-top: 10px;
  @include breakpoint($medium-up) {
    font-size: 14px;
    padding-top: 18px;
    margin-top: 18px;
  }
  h2 {
    text-transform: uppercase;
    font-size: 19px;
  }
  h5 {
    text-transform: uppercase;
    margin-top: 18px;
    font-size: 14px;
  }
  .product-benefits__list {
    @include breakpoint($medium-down) {
      padding-bottom: 10px;
    }
  }
}

.product-full__detail-title {
  cursor: pointer;
  margin: 0;
  padding: 0 0 5px;
  @include breakpoint($medium-up) {
    cursor: auto;
    padding-bottom: 7px;
    .product-full__ingredients & {
      cursor: pointer;
    }
  }
  .icon {
    cursor: pointer;
    float: $rdirection;
    @include breakpoint($medium-up) {
      display: none;
      .product-full__ingredients & {
        display: block;
      }
    }
  }
}

.product-full__detail-content {
  display: none;
  margin-bottom: 10px;
  @include breakpoint($medium-up) {
    display: block;
    .product-full__ingredients & {
      display: none;
    }
  }
  &--important {
    font-family: 'avenir-heavy';
  }
  &--open {
    display: block;
  }
}

.product-full__cruelty-free-logo {
  width: 50px;
}

// power-reviews overrides
.product-full__header,
.product-full__details {
  .pr-snippet-stars-container {
    @include breakpoint($medium-up) {
      display: inline-block !important;
    }
  }
  .p-w-r {
    min-width: 105px;
    .pr-snippet .pr-snippet-stars-png .pr-rating-stars {
      vertical-align: top;
    }
    .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars {
      float: none !important;
      @include breakpoint($medium-up) {
        float: $ldirection !important;
      }
    }
    .pr-qa-snippet-container {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
  div[aria-label*='0.0'] .pr-snippet-rating-decimal {
    display: none;
  }
}

.product-full__inv-status {
  width: 100%;
  .product-add-to-waitlist {
    width: 100%;
  }
  .product__inventory-status {
    &-item {
      @include swap_direction(margin, 6px 0);
      font-size: 14px;
      color: $color-red;
      text-align: center;
    }
    &-container {
      font-weight: bold;
      .product__inventory-status {
        margin: 10px 5px;
        li {
          margin: 0;
        }
      }
      @include breakpoint($medium-up) {
        width: 100%;
        display: flex;
        flex: 1;
        margin: 10px auto;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

// @TODO - should have more specificity and moved inside _tooltipster.scss
.tooltipster-container {
  background: $color-black;
  color: $color-white;
  max-width: 300px;
  a {
    color: $color-white;
    text-decoration: underline;
  }
  .tooltipster-content {
    font-size: 14px;
    line-height: normal;
    overflow: hidden;
    padding: 15px;
    text-transform: uppercase;
  }
}
